export const projects = [
  {
    title: "Monopoly Go!",
    type: "Scopely",
    date: "Present",
    description:
      "Core experience and quality improvements on the client development team",
    link: "https://www.monopolygo.com",
    header: "./mgo.webp",
    tags: ["Unity", "C#", "MVVM"],
  },
  {
    title: "Kingdom Maker - Web Portal",
    type: "Scopely",
    date: "Fall 2023",
    description:
      "Bespoke CMS, backend migration tooling, and complete frontend revamp",
    link: "https://www.kingdommaker.com",
    header:"./km.webp",
    tags: ["Typescript", "React", "Koa.js", "Redux", "AWS", "Redis"],
  },
  {
    title: "Project YOMI (Working Title)",
    type: "Personal Project",
    date: "In Development",
    description:
      "Online multiplayer turn-based fighting game for mobile platforms",
    link: "https://www.github.com/cboveda/ProjectYOMI",
    header:"./yomi.webp",
    tags: ["Unity", "C#", "Unity Netcode", "Unity Game Services"],
  },
  {
    title: "Far Flung: A Psyche Mission Adventure",
    type: "Capstone Project",
    date: "Spring 2022",
    description:
      "Web-based educational game that features five unique mini-games and an engaging narrative campaign",
    link: "https://www.github.com/cboveda/2021_20G_Web-Based-Game_Far-Flung",
    header:"./psyche.png",
    tags: ["Unity", "C#", "CI/CD", "Unit Testing"],
  },
];

export const endorsements = [
  {
    image: "./kw.webp",
    name: "Kyra Wayne",
    title: "Sr. Software Engineer III",
    company: "Nike",
    quote:
      "Through my years of knowing Christopher Boveda he has never ceased to amaze me. The qualities that are often sought after in a team of professionals comes uniquely packaged into one individual with him. He is naturally gifted at picking up new skills and loves to get his hands dirty with end-to-end project development. He looks to lift others up, with an innate ability to mentor and teach others. As a senior developer, I've worked with him to problem solve on projects that he's working on in school, yet find myself learning something new with every interaction. He seeks now to dive into the software industry, ever searching for a new challenge and a new problem to fix. While I have not had the chance to work with him professionally, his raw intelligence, drive, and passion lead me to hope I'll one day get the chance, and know that whatever team does work with him will be an endlessly lucky one.",
    summary:
      "The qualities that are often sought after in a team of professionals comes uniquely packaged into one individual with him...",
  },
  {
    image: "./mm.webp",
    name: "Marcus Maczynski",
    title: "Lead Game Producer",
    company: "Blizzard Entertainment",
    quote:
      "I had the pleasure of working with Chris for just about a year, and can't say enough great things about him. He's a natural leader, using influence without authority to solve complex problems in a team environment. He helped create goals that ultimately shaped the quality of the project, and led the charge against some of the more intimidating challenges. Throughout our time together he demonstrated exceptional follow through, an aptitude for tackling unfamiliar engineering tasks, and a high emotional intelligence that helped solve disputes within his peer group before they became actual issues. He does all of this while creating an environment of creativity and inclusivity to help the team perform at its highest potential. Chris is that rare intersection of performance and potential that any leader would want to bring onto their team either as an individual contributor or as a manager.",
    summary:
      "Chris is that rare intersection of performance and potential that any leader would want to bring onto their team...",
  },
];
